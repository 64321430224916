import { Component, Input} from "@angular/core";
import { Router } from "@angular/router";
import { LangService } from "@app/service/utils";
import { ButtonPrimary } from "@intm-ui/buttons";
import { TypographicTokensModule } from "@intm-ui/typographic-tokens/typographic-tokens.module";

@Component({
  selector: "app-success-message",
  template: `
    <div class="flex flex-col items-center w-full h-full s:px-24 xs:px-16 xs:justify-between">
        <div class="max-w-[546px] flex items-center justify-center flex-col mb-48">
            <div class="mb-32 h-48 w-48 flex justify-center items-center">
                <img src="{{ iconUrl }}" alt="logo-intm-white">
            </div>
            <div class="flex flex-col gap-16 items-center justify-center">
                <intm-text-high-s class="text-center">{{ title }}</intm-text-high-s>
                <intm-text-low-s color="mid" class="text-center">{{ description }}</intm-text-low-s>
            </div>
        </div>
        <div class="xs:py-16 xs:w-full">
            <intm-button-primary class="w-[160px] xs:w-full" (click)="navigateToUrl()" [size]="'normal'">
                <intm-text-low-m [color]="'white'">
                    {{ buttonText }}
                </intm-text-low-m>
            </intm-button-primary>
        </div>
    </div>
  `,
  standalone: true,
  imports: [TypographicTokensModule, ButtonPrimary]
})
export class SuccessMessageComponent  {
    @Input("iconUrl") iconUrl : string = "";
    @Input("title") title : string = "";
    @Input("description") description : string = "";
    @Input("buttonText") buttonText : string = "";
    @Input("buttonUrl") buttonUrl : string = "";

    constructor(
        private router: Router
    ) { }

    public navigateToUrl() {
        this.router.navigate([`${LangService.getLang()}/${this.buttonUrl}`])
    }
}